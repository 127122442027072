<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div
        class="f-2xl p-2 f-bold"
        style="border-bottom: 1px solid #e4e4e4"
        @click="checkIsBranchPoint"
      >
        <span
          class="mr-2 cursor-pointer"
          v-if="$store.state.theme.is_center_point == '0'"
        >
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </span>

        คะแนนของคุณ
      </div>
      <div class="row justify-content-between align-items-center mx-0">
        <div
          class="mt-2 cursor-pointer col-6"
          @click="checkIsBranchPoint"
          v-if="
            $store.state.theme.is_center_point == '0' &&
            $store.state.theme.line_layout != 'Compact'
          "
        >
          <span class="mr-2"
            ><font-awesome-icon :icon="['fas', 'chevron-left']" /></span
          >ประวัติคะแนนสะสม
        </div>
        <div v-if="$route.query.branch_id" class="col-6">
          <b-form-select
            v-model="selected"
            :options="options"
            value-field="branch_id"
            text-field="name"
            size="sm"
            class="mt-3"
            @change="handleChangeBranch"
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Please select an Branch --
              </b-form-select-option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="point-content">
        <PointLogComponent
          :list="pointLog"
          :pointHistoryLog="pointHistoryLog"
          :total="totalPoint"
          :isCollapse="isCollapse"
        />
      </div>
      <Footer
        v-if="$store.state.theme.line_layout == 'Compact'"
        @onClick="$router.push('/profile')"
        text="กลับสู่หน้าหลัก"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb.vue";
import PointLogComponent from "../../../components/point/PointLogComponent.vue";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    Breadcrumb,
    PointLogComponent,
    OtherLoading
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "ประวัติการใช้คะแนน",
          to: "/profile"
        }
      ],
      pointLog: [],
      totalPoint: 0,
      isLoading: true,
      isCollapse: true,
      requestData: { BranchId: 0 },
      selected: 0,
      options: [],
      pointHistoryLog: []
    };
  },
  async created() {
    if (this.$route.query.branch_id) {
      this.getBranch();
    }
    if (this.$store.state.theme.is_center_point == "0") this.getPointByBranch();
    else this.getPointHistoryLog();
  },
  methods: {
    async getPointHistoryLog() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/user/PointLogAllBranch`)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.pointLog = data.detail.point_log;
            this.pointHistoryLog = data.detail.point_history_list;
            this.totalPoint = data.detail.last_point;
            this.isCollapse = data.detail.customer_display == 1 ? true : false;
          }
        });
    },
    async getPointByBranch(branch = this.$route.query.branch_id) {
      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/PointLogByBranch`, {
          BranchId: branch || 0
        })
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.pointLog = data.detail.point_log;
            this.totalPoint = data.detail.last_point;
            this.pointHistoryLog = data.detail.point_history_list;
            this.isCollapse = data.detail.customer_display == 1 ? true : false;
          }
        });
    },
    async getBranch() {
      this.$axios(`${process.env.VUE_APP_API}/api/v1/user/GetUserBranch`).then(
        data => {
          this.selected = this.$route.query.branch_id;
          this.options = data.detail;
        }
      );
    },
    handleChangeBranch() {
      this.getPointByBranch(this.selected);
    },
    checkIsBranchPoint() {
      if (this.$store.state.theme.is_center_point == "0")
        return this.$router.push("/branchpoint");
      return this.$router.push("/point");
    }
  }
};
</script>

<style lang="scss" scoped>
.point-content {
  margin: 1rem;
}
</style>
